import React from 'react';
export default class About extends React.Component{
    constructor(){
        super()
        this.state ={}
        this.animate = this.animate.bind(this);
    }
    animate(){
        let elem = document.querySelectorAll(".opacity0");
        for(let i=0; i<elem.length; i++){
            let topPos = elem[i].getBoundingClientRect().top;
            if(topPos < window.innerHeight/1.5){
                if(elem[i].className.indexOf("animate") === -1){
                    elem[i].className += " animate";
                }
            }
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', this.animate);       
    }
    render(){
        return(
            <section className="about" id="about">
                <div className="container">
                    <div className="title fromBottom opacity0">
                        <h2>About</h2>
                        <span>A small introduction about my self</span>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 fromLeft opacity0">
                            <div>
                                <h3>Santhanaraj</h3>
                                <p>Senior Web Specialist from Chennai</p>
                                <p>As a graduate of Computer Science and Engineering with 6+ years commercial experience working in various web related roles, I have a wide range of skills; front end development, design, and back end development.</p>
                                <p>To work in a healthy, innovative and challenging environment extracting the best out of me, which is conducive to learn and grow at professional as well as personal level thereby directing my future endeavors as an asset to the organization.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 profilePic mcenter fromRight opacity0">    
                            <div>            
                                <img src={require('./../../assets/images/santhanaraj.jpg')} alt="Santhanaraj"/> 
                            </div>                   
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}