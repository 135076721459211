import React, { Component } from 'react';

let parallax = 0;

export default class Home extends Component{
    
    componentWillMount(){
        window.addEventListener('scroll', function(){
            parallax = window.pageYOffset * -0.3;            
        });
        setTimeout(function(){
            document.getElementById("root").style.display = 'block';
            document.getElementById("floatingCirclesG").style.display = 'none';
        }, 1500);
    }
    
    
   
    render(){
        return(
            <section className="home" id="home" style={{backgroundPosition: '50% '+parallax +'px'}}>
                <div>
                    <h1 className={"fromBottom animate"}>I'm Santhanaraj</h1>
                    <h3 className={"fromBottom animate"}>A Senior Web Specialist</h3>
                </div>                
            </section>
        )
    }
}