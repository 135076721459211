import React from 'react';

export default class Services extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            services:[
                {
                    desc:"Providing end to end web soultions and turning websites into profitable. Development of HTML emailers/ newsletters.",
                    title:"Website design & Development"
                },
                {
                    desc:"Development of hybrid mobile applications(iOS/Android) with React Native. focusing on real-time interactive applications",
                    title:"Mobile Application"
                },
                {
                    desc:"Stats is a key, analying and audit the stats and visualize it with easy and understandable graphics and chart real-time.",
                    title:"Data visualization"
                }
            ]
        }
        //this.animateEdu = this.animateEdu.bind(this);
    }

    animateEdu(){
        let elemEdu = document.querySelectorAll("#services .opacity0");
        let topPosEdu = document.querySelector('#services .title').getBoundingClientRect().top;
        for(let i=0; i<elemEdu.length; i++){            
            if(topPosEdu < window.innerHeight/1.5){
                if(elemEdu[i].className.indexOf("animate") === -1){
                    elemEdu[i].className += " animate";
                }
            }
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', this.animateEdu);       
    }
    render(){
        return(
            <section className="services" id="services">
                <div className="container">
                    <div className="title fromBottom opacity0">
                        <h2>Focusing on</h2>
                    </div>
                    <div className="row dataset col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <ul>{
                                this.state.services.map((data, key) =>(
                                    <li className="item" key={key}>
                                        <div className="marker"></div>
                                        <div className="content fromTop opacity0">
                                            <h4 className="devices">{data.title}</h4>
                                            <span>{data.desc}</span>
                                        </div>
                                        <div className="year desktop">{data.title}</div>
                                    </li>
                                ))  
                            }                          
                        </ul>
                        <div className="line"></div>
                    </div>
                </div>
            </section>
        )
    }
}