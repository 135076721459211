import React from 'react';
export default class Skills extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          script:[
            {
              skill:"javascript",
              level:92
            },
            {
              skill:"React js / React Native",
              level:90
            },
            {
              skill:"Angular js",
              level:88
            },
            {
              skill:"d3.js",
              level:75
            },
            {
              skill:"jQuery",
              level:95
            },
            {
              skill:"Webpack / Gulp",
              level:85
            }
          ],
          ui:[
            {
              skill:"HTML5",
              level:92
            },
            {
              skill:"SASS/CSS3",
              level:96
            },
            {
              skill:"Photoshop CS6",
              level:75
            }           
          ],
          server:[
            {
              skill:"Node js",
              level:92
            },
            {
              skill:"Php",
              level:80
            }        
          ]
        }

        
        this.animate = this.animate.bind(this);
        //this.progres = this.progres.bind(this);
    }
    animate(){
      let elem = document.querySelectorAll(".opacity0");
      for(let i=0; i<elem.length; i++){
          let topPos = elem[i].getBoundingClientRect().top;
          if(topPos < window.innerHeight/1.5){
              if(elem[i].className.indexOf("animate") === -1){
                  elem[i].className += " animate";
              }
          }
      }
    }
    
    progress(){  
      if(document.querySelector('.skillFirst').getBoundingClientRect().top <  window.innerHeight/1.5){  
        let pbar = document.querySelectorAll('.progressBar');
        for(let i=0; i<pbar.length; i++){
            pbar[i].style.width =  pbar[i].dataset.val+'%';
        }
      }
    }

    componentDidMount(){
      window.addEventListener('scroll', this.animate);
      window.addEventListener('scroll', this.progress);
  }

    //style={{width : data.level+'%'}}
    render(){
        return(
            <section className="skills" id="skills">
                <div className="container">
                    <div className="title opacity0 fromBottom">
                        <h2>Expertise Skills</h2>
                        <span>I can say i’m good at</span>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 skillFirst">    
                          <div className="skillName" >
                            <h4>Front-End Development</h4>                          
                            <ul>
                              {
                                this.state.script.map((data, key) => (                              
                                  <li key={key}>
                                    <p>{data.skill}</p>
                                    <div className="progress"><div className="progressBar frontend" data-val={data.level}><span>{data.level +'%'}</span></div></div>
                                  </li>
                                ))
                              }                            
                            </ul>
                          </div> 
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 skillSecond">
                        <div className="skillName">
                          <h4>UI/UX Design and Development</h4>
                          <ul>
                              {
                                this.state.ui.map((data, key) => (                              
                                  <li key={key}>
                                    <p>{data.skill}</p>
                                    <div className="progress"><div className="progressBar ui" data-val={data.level}><span>{data.level}%</span></div></div>
                                  </li>
                                ))
                              }                            
                            </ul>
                        </div>
                        <div className="skillName backendSec">
                          <h4>Back-End Development</h4>
                          <ul>
                              {
                                this.state.server.map((data, key) => (                              
                                  <li key={key}>
                                    <p>{data.skill}</p>
                                    <div className="progress"><div className="progressBar backend" data-val={data.level}><span>{data.level}%</span></div></div>
                                  </li>
                                ))
                              }                            
                            </ul>
                        </div>
                      </div>
                    </div>                   
                </div>                
            </section>
        )
    }   
}

