import React from 'react';

export default class Contact extends React.Component{
    constructor(props){
        super(props);
        this.errorMsg = "";
        this.formStatus = "";
        this.state = {
            name:'',
            email: '',
            message: '',
            formErrors:false,
            nameValid: false,
            emailValid:false            
        };
        this.handleChangeEven = this.handleChangeEven.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);      
        this.animate = this.animate.bind(this);
    }
    
    handleSubmit(event){
        if(this.state.formErrors){
            var data = {
                name: this.state.name,
                email: this.state.email,
                message:this.state.message
            }; 
            fetch('https://santaz.in/insert.php', { 
                mode: 'no-cors',
                method: 'POST',
                body: JSON.stringify(data)
              }).then(response => {                
                  this.errorMsg = "Your message submitted successfully!!!";
                  this.setState({name:'', email:'', message:'', formErrors:false})
              });
        }else{
            this.formStatus = '';
            this.errorMsg = "Please enter valid information";
        }
        event.preventDefault();
        return false;        
    }
    handleChangeEven(event){        
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        
        this.setState({[name]: value},
            () => { this.validateField(name, value) });
    }
    
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let nameValid = this.state.nameValid;
        
        switch(fieldName) {
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if(!emailValid){
                value.length === 0 ?  this.errorMsg = "Please enter email address" :  this.errorMsg = "Please enter valid email address";
            }else{
                this.errorMsg = '';
            }
            break;
          case 'name':
            nameValid = value.length >= 2;
            if(!nameValid){
                value.length === 0 ?  this.errorMsg = "Please enter your name" :  this.errorMsg = "Please enter valid  name";
            }else{
                this.errorMsg = '';
            }
            break;
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        emailValid: emailValid,
                        nameValid: nameValid
                      }, this.validateForm);
        this.formStatus = "error";
        if(this.state.formErrors){
            this.formStatus = "success";
        }else{
            
        }
    }
    validateForm() {
        this.setState({formErrors: this.state.emailValid && this.state.nameValid});
    }
      
    animate(){
        let elem = document.querySelectorAll(".opacity0");
        for(let i=0; i<elem.length; i++){
            let topPos = elem[i].getBoundingClientRect().top;
            if(topPos < window.innerHeight/1.5){
                if(elem[i].className.indexOf("animate") === -1){
                    elem[i].className += " animate";
                }
            }
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', this.animate);       
    }
   
    render(){
        return(
            <section className="contact" id="contact">
                <div className="container">
                    <div className="title fromBottom opacity0">
                        <h2>Get in Touch</h2>
                        <span>Please feel free if you would like to have a chat.</span>
                    </div>
                    {
                        /*
                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 contactDetails fromLeft opacity0">
                            <h4 className="mcenter">Contact Details</h4>
                            <ul>
                                <li><span className="glyphicon glyphicon-phone" aria-hidden="true"></span> <a href={"tel:00919789021694"}>+91 9789021694</a></li>
                                <li><span className="glyphicon glyphicon-envelope" aria-hidden="true"></span> <a href={"mailto:daniel.santhanaraj@gmail.com"}>daniel.santhanaraj@gmail.com</a></li>
                            </ul>
                        </div>
                        */
                    }
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 contactform fromRight opacity0"></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 contactform fromRight opacity0">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">                            
                                <input type="text" className="form-control" id="name" placeholder="Your name" name="name" onChange={this.handleChangeEven} value={this.state.name} ref="name"/>
                            </div>
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">                            
                                <input type="email" className="form-control" id="email" placeholder="Your email address" name="email" value={this.state.email} onChange={this.handleChangeEven} ref="email"/>
                            </div>
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <textarea  rows="4" placeholder="Your message..." name="message" onChange={this.handleChangeEven} value={this.state.message} ref="message"></textarea>
                            </div>                       
                            
                            <button type="submit" className="btn btn-default">Submit</button>
                            <span className= {'message '+ this.formStatus}>{this.errorMsg}</span>
                        </form>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 contactform fromRight opacity0"></div>
                </div>
            </section>
        )
    }
}