import React, { Component } from 'react';
import Header from './components/Header';
//import Footer from './components/Footer';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Skills from './components/pages/Skills';
//import Education from './components/pages/Education';
import Services from './components/pages/Services';
import Portfolio from './components/pages/Portfolio';
import Contact from './components/pages/Contact';

class App extends Component {
  constructor(){
    super();
    this.state = {
        fixedClass:'absolute',
        menuItem:{ home:'act', about:'', skills:'', portfolio:'', education:'', contact:'' }
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.realTimeScroll = this.realTimeScroll.bind(this);
  }
  componentDidMount(){
    window.menuNav()
    window.addEventListener('scroll', this.handleScroll);
    try{
      window.addEventListener('scroll', this.realTimeScroll);
    }catch(err){}
  }
  componentWillMount(){      
      this.handleScroll.call();
  }
  
  handleScroll(){
    let windowPos = window.pageYOffset;
    if(windowPos> 350){        
      this.setState({ fixedClass:'fixed' })
    }else{
      this.setState({ fixedClass:'absolute' })
    }        
  }

  realTimeScroll(){
    let home = document.querySelector("#home").getBoundingClientRect().top;
    let about = document.querySelector("#about").getBoundingClientRect().top;
    let skills = document.querySelector("#skills").getBoundingClientRect().top;
    let portfolio = document.querySelector("#portfolio").getBoundingClientRect().top;
    //let education = document.querySelector("#education").getBoundingClientRect().top;
    let services = document.querySelector("#services").getBoundingClientRect().top;
    let contact = document.querySelector("#contact").getBoundingClientRect().top;
    if(home < 150){
      this.setState({
        menuItem:{ home:'act', about:'', services:'', skills:'', portfolio:'', education:'', contact:''}
      });
    }
    if(about < 150){
      this.setState({
        menuItem:{ home:'', about:'act', services:'', skills:'', portfolio:'', education:'', contact:'' }
      });
    }
    if(skills < 150){
      this.setState({
        menuItem:{ home:'', about:'', services:'', skills:'act', portfolio:'', education:'', contact:'' }
      });
    }
    if(services < 150){
      this.setState({
        menuItem:{ home:'', about:'', services:'act', skills:'', portfolio:'', education:'', contact:'' }
      });
    }
    if(portfolio < 150){
      this.setState({
        menuItem:{ home:'', about:'', services:'', skills:'', portfolio:'act', education:'', contact:'' }
      });
    }
    /*
    if(education < 100){
      this.setState({
        menuItem:{ home:'', about:'', services:'', skills:'', portfolio:'', education:'act', contact:'' }
      });
    }*/
    if(contact < 150){
      this.setState({
        menuItem:{ home:'', about:'', services:'', skills:'', portfolio:'', education:'', contact:'act' }
      });
    }
    
  }

  render() {
    return (
      <div className="App">          
          <Home id="home" />
          <Header menu={this.state}/>
          <About id="about"/>
          <Services id="services"/>
          <Skills id="skills" />
          <Portfolio id="portfolio" />
          {
            /*<Education id="education"/> */
          }
          
          <Contact id="contact"/>
      </div>
    );
  }
}

export default App;
