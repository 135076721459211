import React, {Component} from 'react';
import { BrowserRouter,  NavLink } from 'react-router-dom';

//Route,
//activeClassName="active"
let rightVal = '';
export default class Header extends Component{   
     
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            windowWidth: document.documentElement.clientWidth
        };
        
        //console.log(this.state.windowWidth)
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({visible: !this.state.visible})
        if(this.state.visible){
            rightVal = 'open';
        }else{
            rightVal ='';
        }
    }
    //style={ this.state.windowWidth < 767 ?  {'right': rightVal +'%'}: null}
    render(){
        return(
          
            <header className={ this.state.windowWidth > 767 ? this.props.menu.fixedClass : rightVal} >
                <div className="devices hmenu" onClick={this.toggleMenu}>
                    <span id="top"></span>
                    <span id="middle"></span>
                    <span id="bottom"></span>
                </div>
                <nav>
                <BrowserRouter>
                    <ul onClick={this.toggleMenu}>
                        <li><NavLink className={this.props.menu.menuItem.home +" menuItem"} exact={true} to="/">Home</NavLink></li>
                        <li><NavLink className={this.props.menu.menuItem.about +" menuItem"} to="/">About</NavLink></li>
                        <li><NavLink className={this.props.menu.menuItem.services +" menuItem"}  to="/">Services</NavLink></li>
                        <li><NavLink className={this.props.menu.menuItem.skills +" menuItem"}  to="/">Skills</NavLink></li>
                        <li><NavLink className={this.props.menu.menuItem.portfolio +" menuItem"}  to="/">Portfolio</NavLink></li>
                        <li><NavLink className={this.props.menu.menuItem.contact +" menuItem"}  to="/">Contact</NavLink></li>
                    </ul>
                </BrowserRouter>
                </nav>
            </header>
        )
    }
}