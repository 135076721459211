import React from 'react';

export default class Portfolio extends React.Component{
    constructor(){
        super();
        this.state = { };
        this.animate = this.animate.bind(this);
    }
    animate(){
        let elem = document.querySelectorAll(".opacity0");
        for(let i=0; i<elem.length; i++){
            let topPos = elem[i].getBoundingClientRect().top;
            if(topPos < window.innerHeight/1.8){
                if(elem[i].className.indexOf("animate") === -1){
                    elem[i].className += " animate";
                }
            }
        }
      }
    componentDidMount(){
        window.addEventListener('scroll', this.animate);       
    }
    render(){
        return(
            <section className="portfolio" id="portfolio">
                <div className="container">
                    <div className="title fromBottom opacity0">
                        <h2>Portfolio</h2>
                        <span>showcase of my latest works</span>
                    </div>
                    <div className="masanarySec">
                        <div className="masanary">
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/ht-new.jpg')} alt="Portfolio" />
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/1.jpg')} alt="Portfolio" />                               
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/elshadai.jpg')} alt="Portfolio" />  
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/matrix.jpg')} alt="Portfolio" /> 
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/3.jpg')} alt="Portfolio" />  
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/4.jpg')} alt="Portfolio" /> 
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/holytalk.jpg')} alt="Portfolio" />  
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/6.jpg')} alt="Portfolio" /> 
                            <img className="fromBottom opacity0 " src={require('./../../assets/images/portfolio/tocso.jpg')} alt="Portfolio" />   
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}